<template>
  <b-card>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <!-- form -->
    <validation-observer ref="sitesCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block withMaxHeight mb-4">

              <div class="section-block__title">
                <feather-icon
                  icon="MapIcon"
                  width="24"
                  height="24"
                />
                <h2>Create site</h2>
              </div>

              <div
                v-if="!loading"
              >
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Name"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      vid="name"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Name"
                        autocomplete="off"
                        maxlength="38"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Contact Name"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Contact Name"
                      vid="contact_name"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.contact_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Contact Name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    v-if="!isClient"
                    label="Company"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Company"
                      vid="company_id"
                      rules="required"
                    >
                      <v-select
                        v-model="form.company_id"
                        :options="options.companies.data"
                        placeholder="Enter name"
                        :reduce="company_id => company_id.id"
                        label="name"
                        @search="filterCompanies($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="NAS"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NAS"
                      vid="nas_id"
                      rules="required"
                    >
                      <v-select
                        v-model="form.nas_id"
                        :options="nass"
                        placeholder="Select NAS"
                        :reduce="nas_id => nas_id.id"
                        label="name"
                        :disabled="isDisabledNasSelection || !form.company_id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Email"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="email|max:255"
                  >
                    <b-form-input
                      v-model="form.email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Phone*"
                  label-for="phone_number"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    vid="phone_number"
                    rules="required|numeric|min:5|max:15"
                  >
                    <vue-tel-input
                      id="register-phone_number"
                      ref="phoneNumber"
                      v-model="form.phone_number"
                      class="form-control pl-0"
                      name="register-phone_number"
                      :preferred-countries="['GB']"
                      :auto-default-country="false"
                      :auto-format="false"
                      :dropdown-options="{
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showDialCodeInList: true
                      }"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mb-50 mt-2">
                  <b-form-checkbox
                    v-if="form.company_id"
                    id="isSameAddress"
                    v-model="isSameAddress"
                    name="checkbox-1"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Same as Company Billing Address
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  v-if="!isSameAddress"
                  label="Address Line 1*"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 1"
                    vid="address1"
                    rules="max:255|required"
                  >
                    <b-form-input
                      v-model="form.address1"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Address Line 1"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="!isSameAddress"
                  label="Address Line 2"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 2"
                    vid="address2"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="form.address2"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Address Line 2"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="!isSameAddress"
                  label="Town / City*"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Town / City "
                    vid="city"
                    rules="max:255|required"
                  >
                    <b-form-input
                      v-model="form.city"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Town / City "
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="!isSameAddress"
                  label="State"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="State"
                    vid="state"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="form.state"
                      :state="errors.length > 0 ? false:null"
                      placeholder="State"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="!isSameAddress"
                  label="Zip / Post Code*"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Zip / Post Code*"
                    vid="postcode"
                    rules="max:255|required"
                  >
                    <b-form-input
                      v-model="form.postcode"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Zip / Post Code"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="!isSameAddress"
                  label="Country*"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    vid="country_id"
                    rules="max:255|required"
                  >

                    <v-select
                      v-model="form.country_id"
                      :options="options.countries.data"
                      :reduce="country_id => country_id.id"
                      placeholder="Select country"
                      label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Create
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>

</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BSpinner, BFormCheckbox, BCard,
} from 'bootstrap-vue'
import {
  required, max, numeric, email,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import axios from '../axios-resolver'

const VueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
// eslint-disable-next-line no-shadow
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
    vSelect,
    VueTelInput,
    BCard,
  },
  data() {
    return {
      name: '',
      required,
      max,
      numeric,
      email,
      isSameAddress: false,
      company: null,
      loading: true,
      isDisabledNasSelection: false,
      nass: [],
      isClient: false,
      form: {
        email: null,
        phone_country_code: null,
        phone_number: null,
        contact_name: null,
        company_id: null,
        nas_id: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postcode: null,
        country_id: null,
      },
    }
  },
  computed: {
    ...mapState('sites', [
      'options',
    ]),
  },
  watch: {
    isSameAddress(val) {
      if (val) {
        this.form.address1 = this.company.billing_address1
        this.form.address2 = this.company.billing_address2
        this.form.country_id = this.company.billingCountry?.id
        this.form.postcode = this.company.billing_postcode
        this.form.state = this.company.billing_state
        this.form.city = this.company.billing_city
      } else {
        this.form.address1 = ''
        this.form.address2 = ''
        this.form.country_id = ''
        this.form.postcode = ''
        this.form.state = ''
        this.form.city = ''
      }
    },
    'form.company_id': function (val) {
      if (val) {
        this.getSelectedCompany()
        this.getNASs()
      }
    },
  },
  async mounted() {
    await this.getItemOptionsList()
    this.loading = false
    this.isDisabledNasSelection = false
    if (this.options.nas.data.length === 1) {
      this.isDisabledNasSelection = true
      this.form.nas_id = this.options.nas.data[0].id
    }

    const userData = JSON.parse(localStorage.getItem('userData'))
    this.isClient = userData.roles.includes('client')
  },
  methods: {
    ...mapActions('sites', [
      'getItemOptionsList',
      'createItem',
    ]),
    async getNASs() {
      try {
        const { data } = await axios.get(`/company/${this.form.company_id}/nas`)

        if (this.nass.length === 1) {
          this.form.nas_id = this.nass[0].id
        }

        this.nass = data.data
        console.log('data', data)
      } catch (error) {
        console.log(error)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    ...mapActions('companyManagement', {
      getCompanyItem: 'getItem',
    }),
    async getSelectedCompany() {
      this.company = await this.getCompanyItem(this.form.company_id)
    },
    filterCompanies(searchValue) {
      this.options.companies.data.filter(item => item.name.includes(searchValue))
    },
    async validationForm() {
      const valid = await this.$refs.sitesCreate.validate()

      if (valid) {
        try {
          let payload = {
            name: this.name,
            ...this.form,
          }
          payload.country_id = payload.country_id.toString()
          if (this.form.phone_number) {
            payload = {
              ...payload,
              ...{ phone_number: this.form.phone_number },
              ...{ phone_country_iso: this.$refs.phoneNumber.phoneObject.country.iso2 },
              ...{ phone_country_code: this.$refs.phoneNumber.phoneObject.country.dialCode },
            }
          } else {
            payload.phone_number = null
            payload.phone_country_iso = null
            payload.phone_country_code = null
          }

          if (this.options.nas.data.length === 1) {
            payload.nas_id = this.options.nas.data[0].id
          }

          if (this.isClient) {
            delete payload.company_id
          }

          await this.createItem(payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'sites-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.sitesCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
